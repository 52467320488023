<template>
   <div>
      <section class="intro">
         <div class="container-ecosana-small"><h1 data-aos="fade-up" data-aos-delay="500"
                                                  class="aos-init aos-animate">ECOSANA</h1>
            <h2 data-aos="fade-up" data-aos-delay="1000" class="aos-init aos-animate">ORGANIC AND NATURAL FOOD</h2>
            <p data-aos="fade-up" data-aos-delay="1500" class="aos-init aos-animate">Always dedicated to the manufacture, packaging
               and distribution of natural and organic food, we offer you an extensive range of products of the highest nutritional quality.</p><a data-aos="fade-up" data-aos-delay="2000"
                                                                           href="#products"
                                                                           class="big-button-ecosana aos-init aos-animate">View products</a></div>
      </section>
      <section class="description">
         <div class="container-ecosana"><h3>HEALTHY AND BALANCED<br>NUTRITIONAL PRODUCTS</h3>
            <div class="content"><h4 data-aos="fade-up" data-aos-delay="300" class="aos-init aos-animate"><span>A BIT MORE</span><br>
               ABOUT ECOSANA</h4>
               <img data-aos="fade-up" data-aos-delay="550" src="./../assets/grain_image.png"
                                   alt="" class="aos-init aos-animate">
               <div data-aos="fade-up" data-aos-delay="800" class="text aos-init aos-animate"><p> Flours, seeds, pulses, nuts, dried fruits, dehydrated products,
                  preserves, etc. to complete a wide range of foods, all of them nutritionally rich, healthy and balanced. In this way, at Ecosana we promote a
                  healthy lifestyle through food, paying special attention to the origin of the products, their agricultural-productive processes
                  and a responsible pricing policy.</p></div>
            </div>
         </div>
      </section>
      <section class="organic-products-icons">
         <div class="icon"><img src="./../assets/motives/aditives.png" alt="">
            <p><span>ADDITIVE</span><br>free</p></div>
         <div class="icon"><img src="./../assets/motives/pesticides.png" alt="">
            <p><span>PESTICIDE</span><br>free</p></div>
         <div class="icon"><img src="./../assets/motives/omg.png" alt="">
            <p><span>GMO</span><br>No genetic modification</p></div>
      </section>
      <section id="products" class="products">
         <div class="container-ecosana"><h3>OUR  <span>PRODUCTS</span></h3>
            <div class="products-content">
               <div data-aos="fade-up" class="product aos-init aos-animate" v-for="product in productsEnd">
                  <img :src="'https://panel.drasanvi.com/img/products/' + product.reference + '.png'" alt="">
                  <p class="name">{{ product.name }}</p>
                  <div v-html="product.presentation"></div>
                  <a target="_blank" :href="'https://panel.drasanvi.com/fichas/4/' + product.reference">Ver ficha</a></div>
            </div>
            <div style="display: flex; justify-content: center">
               <div class="big-button-ecosana" @click="nextPage()">View more</div>
            </div>
         </div>
      </section>
      <footer>
         <div class="container-ecosana">
            <div class="left"><p>Copyright Ecosana 2021. All rights reserved</p></div>
            <div class="right"><p>Polígono Industrial de Villadangos - Calle 1, Parcela 99</p>
               <p>24392 Villadangos del Páramo</p>
               <p>León - España</p>
               <p><a href="mailto:info@drasanvi.com">info@drasanvi.com</a></p>
               <p>Tel: (+34) 987 203 106</p>
               <p>Fax: (+34) 987 203 125</p></div>
         </div>
      </footer>
   </div>
</template>

<script>

    const axios = require('axios');

    export default {
        name: 'English',
        data() {
            return {
                products: [],
                productsEnd: [],
                page: 0
            }
        },
        created() {
            this.getProducts();
        },
        methods: {
            getProducts() {
                let temp = this;
                axios.get('https://panel.drasanvi.com/api/products/en/ecosana')
                    .then(function (response) {
                        temp.products = response.data;
                        temp.paginatedList();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            nextPage() {
                this.page++;
                this.paginatedList()
            },
            paginatedList() {
                const start = this.page * 20;
                const end = start + 20;
                let current = start;

                console.log(start);
                console.log(end);


                while (current < end && this.products[end - (end - current)]) {
                    this.productsEnd.push(this.products[end - (end - current)]);
                    current++;
                }
                console.log(this.productsEnd);
            }
        }
    }
</script>
