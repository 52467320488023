<template>
    <div>
        <section class="intro">
            <div class="container-ecosana-small"><h1 data-aos="fade-up" data-aos-delay="500"
                                                     class="aos-init aos-animate">ECOSANA</h1>
                <h2 data-aos="fade-up" data-aos-delay="1000" class="aos-init aos-animate">Alimentación ecológica y
                    natural</h2>
                <p data-aos="fade-up" data-aos-delay="1500" class="aos-init aos-animate">Siempre dedicados a la
                    fabricación, envasado y distribución de alimentos naturales y ecológicos, te ofrecemos una extensa
                    gama de productos de la más alta calidad nutricional.</p><a data-aos="fade-up" data-aos-delay="2000"
                                                                                href="#products"
                                                                                class="big-button-ecosana aos-init aos-animate">Ver
                    productos</a></div>
        </section>
        <section class="description">
            <div class="container-ecosana"><h3>PRODUCTOS NUTRICIONALES <br> SANOS Y EQUILIBRADOS</h3>
                <div class="content"><h4 data-aos="fade-up" data-aos-delay="300" class="aos-init aos-animate"><span>UN POCO MÁS</span><br>
                    DE ECOSANA</h4><img data-aos="fade-up" data-aos-delay="550" src="./../assets/grain_image.png"
                                        alt="" class="aos-init aos-animate">
                    <div data-aos="fade-up" data-aos-delay="800" class="text aos-init aos-animate"><p> Harinas,
                        semillas, legumbres, frutos secos, deshidratados, conservas, etc. hasta completar un amplio
                        abanico de alimentos, todos ellos nutricionalmente ricos, sanos y equilibrados.</p>
                        <p>De esta forma, desde Ecosana fomentamos la salud a través de la alimentación, prestando
                            especial atención al origen de los productos, a sus procesos agrícolas-productivos y a una
                            política de precios responsable. </p></div>
                </div>
            </div>
        </section>
        <section class="organic-products-icons">
            <div class="icon"><img src="./../assets/motives/aditives.png" alt="">
                <p><span>LIBRE</span><br>de aditivos</p></div>
            <div class="icon"><img src="./../assets/motives/pesticides.png" alt="">
                <p><span>SIN</span><br>Sin pesticidas</p></div>
            <div class="icon"><img src="./../assets/motives/omg.png" alt="">
                <p><span>OGM</span><br>Sin modificaciones genéticas</p></div>
        </section>
        <section id="products" class="products">
            <div class="container-ecosana"><h3>NUESTROS <span>PRODUCTOS</span></h3>
                <div class="products-content">
                    <div data-aos="fade-up" class="product aos-init aos-animate" v-for="product in productsEnd">
                        <img :src="'https://panel.drasanvi.com/img/products/' + product.reference + '.png'" alt="">
                        <p class="name">{{ product.name }}</p>
                        <div v-html="product.presentation"></div>
                        <a target="_blank" :href="'https://panel.drasanvi.com/fichas/1/' + product.reference">Ver ficha</a></div>
                </div>
                <div style="display: flex; justify-content: center">
                    <div class="big-button-ecosana" @click="nextPage()">Ver más</div>
                </div>
            </div>
        </section>
        <footer>
            <div class="container-ecosana">
                <div class="left"><p>Copyright Ecosana 2021. Todos los derechos reservados</p></div>
                <div class="right"><p>Polígono Industrial de Villadangos - Calle 1, Parcela 99</p>
                    <p>24392 Villadangos del Páramo</p>
                    <p>León - España</p>
                    <p><a href="mailto:info@drasanvi.com">info@drasanvi.com</a></p>
                    <p>Tel: (+34) 987 203 106</p>
                    <p>Fax: (+34) 987 203 125</p></div>
            </div>
        </footer>
    </div>
</template>

<script>

    const axios = require('axios');

    export default {
        name: 'Spanish',
        data() {
            return {
                products: [],
                productsEnd: [],
                page: 0
            }
        },
        created() {
            this.getProducts();
        },
        methods: {
            getProducts() {
                let temp = this;
                axios.get('https://panel.drasanvi.com/api/products/es/ecosana')
                    .then(function (response) {
                        temp.products = response.data;
                        temp.paginatedList();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            nextPage() {
                this.page++;
                this.paginatedList()
            },
            paginatedList() {
                const start = this.page * 20;
                const end = start + 20;
                let current = start;

                console.log(start);
                console.log(end);


                while (current < end && this.products[end - (end - current)]) {
                    this.productsEnd.push(this.products[end - (end - current)]);
                    current++;
                }
                console.log(this.productsEnd);
            }
        }
    }

</script>
