import Vue from 'vue'
import VueRouter from 'vue-router'
import Spanish from '../views/Spanish.vue'
import English from '../views/English.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Spanish',
    component: Spanish
  },
  {
      path: '/en',
      name: 'English',
      component: English
  },
    /*
    {
        path: '/',
        name: 'Spanish',
        component: Spanish
    },*/
    /*
  {
    path: '/es',
    name: 'English',
    component: () => import(// webpackChunkName: "about" //'../views/About.vue')
  }*/
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
